._1fBPd {
  background-color: #2E2D2B;
  color: #fff;
  display: inline-block;
  padding: 4px 5px;
  text-align: center;
  font-size: 1rem;
  line-height: 1.2rem;
  vertical-align: middle;
  border-radius: 4%; }

._1fBPd > span {
  margin-left: 10px;
  margin-right: 10px; }

._3JTsr, ._1f6bU, ._1ts2E, ._3kO56, ._xJr6O, ._2w--N {
  background-color: #339F52 !important; }

._2p_VS, ._vOGoP, ._1baBY, ._384NZ, ._1baBY, .__DTbM {
  background-color: #565553 !important; }

._2RRnm {
  background-color: #AC2230 !important; }

._3wV_c > h1 {
  font-size: 4rem;
  font-weight: 300;
  line-height: 1.2;
  color: #004E36; }

._3wV_c > p {
  font-size: 1.25rem;
  font-weight: 300;
  color: #757575; }

#_l-wJ6 {
  font-weight: 100;
  font-size: 1em;
  width: 460px; }

._3XGij._1HCtb {
  width: 100% !important;
  padding: 30px 20px; }

._3XGij._1Gz_I {
  width: 100% !important;
  padding: 30px 20px; }

._1tWOf ._iXACn {
  z-index: 9999 !important; }

._1tWOf ._3Dd3f {
  z-index: 9998 !important; }

body {
  font-weight: 700 !important;
  font-family: Circular Pro,Arial,sans-serif !important; }

input:focus {
  outline: none; }

._3WhfT {
  font-family: 'Circular Std', "Trebuchet MS", "Helvetica Neue", Arial, Helvetica, sans-serif;
  font-size: 52px;
  color: #2E2D2B; }

._91BSE {
  font-family: Corda Std,Georgia,serif;
  font-weight: 500;
  display: block;
  -webkit-font-smoothing: antialiased;
  font-size: 2.5rem;
  line-height: 3rem; }

._2siDA {
  font-size: 2.5rem;
  line-height: 3rem;
  font-weight: 700; }

._1O8JO {
  font-size: 1.875rem;
  line-height: 2.375rem;
  font-weight: 700; }

._nGgq_ {
  font-family: Circular Pro,Arial,sans-serif;
  font-weight: 500;
  font-size: 1.5rem;
  line-height: 1.5;
  -webkit-font-smoothing: antialiased; }

._2gKsT {
  font-family: Circular Pro,Arial,sans-serif;
  font-size: 1rem;
  font-weight: 350;
  line-height: 1.5;
  -webkit-font-smoothing: antialiased; }

._3XYjp:after {
  content: "";
  width: 3.75rem;
  border-bottom: 1px solid #2e2d2b;
  display: block;
  margin-top: var(--fixed-spacer-xs);
  margin-bottom: var(--fixed-spacer-xs); }

._PBBG5 {
  font-family: Circular Pro,Arial,sans-serif;
  font-weight: 700;
  display: block;
  -webkit-font-smoothing: antialiased; }
  ._PBBG5 ._2Y72N {
    font-size: 1.875rem;
    line-height: 2.375rem; }
  ._PBBG5 ._1bp6Q {
    font-size: 1.375rem;
    line-height: 1.75rem; }

._1Qryk {
  color: #AC0000;
  display: block;
  text-align: left;
  font-weight: 350;
  font-size: .875rem;
  line-height: 1.25rem; }
  ._1Qryk::before {
    content: "";
    display: inline-block;
    background: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB3aWR0aD0iMjAiIGhlaWdodD0iMjAiIHZpZXdCb3g9IjAgMCAyMCAyMCI+CiAgICA8ZGVmcz4KICAgICAgICA8cGF0aCBpZD0ieC1pY29uLXBhdGgiIGQ9Ik03LjkgNi41MTRsNC4xMTIgNC4xMTItMS40MTQgMS40MTQtNC4xMTItNC4xMTItNC4xMTIgNC4xMTJMLjk2IDEwLjYyNmw0LjExMi00LjExMkwuOTYgMi40MDIgMi4zNzQuOTg4IDYuNDg2IDUuMSAxMC41OTguOTg4bDEuNDE0IDEuNDE0TDcuOSA2LjUxNHoiLz4KICAgIDwvZGVmcz4KICAgIDxnIGZpbGw9Im5vbmUiIGZpbGwtcnVsZT0iZXZlbm9kZCIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoNCA0KSI+CiAgICAgICAgPG1hc2sgaWQ9IngtaWNvbi1tYXNrIiBmaWxsPSIjZmZmIj4KICAgICAgICAgICAgPHVzZSB4bGluazpocmVmPSIjeC1pY29uLXBhdGgiLz4KICAgICAgICA8L21hc2s+CiAgICAgICAgPHVzZSBmaWxsPSIjYWMwMDAwIiBmaWxsLXJ1bGU9Im5vbnplcm8iIHhsaW5rOmhyZWY9IiN4LWljb24tcGF0aCIvPgogICAgICAgIDxnIGZpbGw9IiNhYzAwMDAiIG1hc2s9InVybCgjeC1pY29uLW1hc2spIj4KICAgICAgICAgICAgPHBhdGggZD0iTS00LTRoMjB2MjBILTR6Ii8+CiAgICAgICAgPC9nPgogICAgPC9nPgo8L3N2Zz4K);
    width: 25px;
    height: 15px; }

._C5htJ {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center; }

.__0vd6 {
  display: flex;
  flex-direction: column; }

:root {
  --amplify-primary-color: #004E36;
  --amplify-primary-tint: #038C70;
  --amplify-primary-shade: #006F46
; }

._1vp2Y {
  text-align: center; }

._qyaHC {
  padding: 20px;
  border-bottom: solid 2px #004E36;
  justify-content: space-between; }

._3WhfT {
  font-family: 'Circular Std', 'Trebuchet MS', 'Helvetica Neue', Arial, Helvetica, sans-serif;
  font-size: 52px;
  color: #2e2d2b; }

._3Wxzl {
  padding-left: 5px;
  font-family: Circular Pro, Arial, sans-serif;
  font-size: 1rem;
  font-weight: 350;
  line-height: 1.5;
  -webkit-font-smoothing: antialiased; }
